import * as React from 'react'

import { CloseButton, Flex, Link, Select, SelectProps, Text, useColorModeValue } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { CartProductMeta } from './CartProductMeta'

type CartItemProps = {
  isGiftWrapping?: boolean
  name?: string
  quantity: number
  price: string
  imageUrl?: string
  productTags: any
  databaseId: any
  key: string
  slug: string
  onChangeQuantity: (qty: number) => void
  onClickGiftWrapping?: () => void
  onClickDelete?: (key:any) => void
}

const QuantitySelect = (props: SelectProps) => {
  return (
    <Select
      maxW="64px"
      aria-label="Select quantity"
      focusBorderColor={useColorModeValue('blue.500', 'blue.200')}
      {...props}
      id="qty_select"
    >
      <option>1</option>
      <option>2</option>
      <option>3</option>
      <option>4</option>
      <option>5</option>
      <option>6</option>
      <option>7</option>
      <option>8</option>
      <option>9</option>
      <option>10</option>

    </Select>
  )
}

export const CartItem = (props: CartItemProps) => {
  
  const {
    name,
    quantity,
    imageUrl,
    price,
    key,
    slug,
    productTags,
    onChangeQuantity,
    onClickDelete,
  } = props

  // var priceSub = price.slice(0, - 3);


   const [quantity1, setSelectedOption] = useState(1)

  useEffect(() => {
    const storedSelectedOption = parseInt(sessionStorage.getItem('quantity1') || '1')
    setSelectedOption(storedSelectedOption)
  }, [])

  const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(parseInt(e.target.value))
    sessionStorage.setItem('quantity1', e.target.value)
  }
  

  return (
    <Flex direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
      <Link href={`/product${slug}`} w='full' _hover={{textDecoration:"none", color:"#0962e5"}}>
      <CartProductMeta
        quantity={quantity}
        name={name}
        image={imageUrl}
        productTags={productTags}
        />
        </Link>
      {/* Desktop */}
      <Flex width="full" justify="space-between" alignItems="center" display={{ base: 'none', md: 'flex' }}>
        <QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity(parseInt(e.currentTarget.value));
          }}
        />

        <Text fontWeight="bold"> { price }</Text>
        <CloseButton aria-label={`Delete ${name} from cart`} onClick={onClickDelete} />

      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{ base: 'flex', md: 'none' }}
      >
        <Link fontSize="sm" textDecor="underline">
          Delete
        </Link>
        <Text fontWeight="bold"> { price }</Text>
        <QuantitySelect
          value={quantity}
          onChange={(e) => {
            onChangeQuantity(parseInt(e.currentTarget.value));
          }}
        />
      </Flex>
    </Flex>
  )
}