import * as React from 'react'

import {
  Box,
  HStack,
  Icon,
  Image,
  Link,
  Skeleton,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'

export type CartProductMetaProps = {
  name?: string
  image?: string
  quantity: number
  productTags?: {
    nodes?: [
      {
        name: string
       
      }
    ]
  }
}


export const CartProductMeta = (props: CartProductMetaProps) => {
  const { image, name, productTags, quantity } = props
  return (
    <Stack direction="row" spacing="5" width="full">
      <Image
        rounded="lg"
        width="120px"
        height="120px"
        fit="cover"
        src={image}
        alt={name}
        draggable="false"
        loading="lazy"
        fallback={<Skeleton />}
      />
      <Box pt="4">
        <Stack spacing="0.5">
          <Text fontWeight="bold">{name} </Text>
          <Text fontWeight="bold" color="blue.500">x {quantity}</Text>
          <Text color={mode('gray.600', 'gray.400')} fontSize="sm" display="flex">
           {/* {productTags?.nodes?.map((item)=> (item.name))} */}
           {productTags?.nodes?.map((item:any, index, array) => {
                      const lastElement = array.length - 1;
                      
                      if (lastElement == index) {
                        return <Text color="base.baseColor" fontWeight="700" > {item.name} </Text>
                      } else {
                        return <Text color="base.baseColor" fontWeight="700" marginRight="2"> {item.name}, </Text>
                      }
                    }
                  )}
           
          </Text>
        </Stack>
      </Box>
    </Stack>
  )
}